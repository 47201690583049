  .editor-container {
    flex-grow: 1;
    flex-basis: 0;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    padding: .5rem;
  }

  .editor-container.collapsed {
    flex-grow: 0;
  }

  .editor-container.collapsed .CodeMirror-scroll {
    position: absolute;
    overflow: hidden !important;
  }

  .expand-collapse-btn {
    margin-left: .5rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }

  .editor-title {
    display: flex;
    justify-content: space-between;
    background-color: hsl(225, 6%, 13%);
    color: white;
    padding: .5rem .5rem .5rem 1rem;
    border-top-right-radius: .5rem;
    border-top-left-radius: .5rem;
  }

  .CodeMirror {
    height: 100% !important;
  }

  .code-mirror-wrapper {
    flex-grow: 1;
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    overflow: auto;
  }

  .shareImgContainer {
    padding: 0 20px 10px 20px;
    position: relative;
  }

  .shareImgUp {
    width: 100%;
    object-fit: cover;
  }

  .shareCancelImg {
    position: absolute;
    top: 0;
    color: red;
    right: 20px;
    cursor: pointer;
    opacity: 0.7;
  }

  .login {
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: auto;
  }

  .loginContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .invalid-password {
    color: red;
  }

  .confirm-txt {
    width: 100%;
    height: 32vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-link.layout-topbar-button:hover>span {
    display: block;
  }

  .layout-topbar .layout-topbar-button {
    width: auto !important;
  }

  li.slide {
    z-index: 10 !important;
  }
  header {
    height: 58px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
  }
  
  .nav-area {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    height: 58px;
  }
  
  .logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
  }
  
  .menus {
    display: flex;
    list-style: none;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
  
  .menu-items button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  .menu-items a,
  .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #f2f2f2;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .toolbar-config {
    position: fixed;
    top: 0;
    padding: 0;
    right: 0;
    width: 27rem;
    z-index: 1001;
    height: 100vh;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%) !important;
    color: var(--text-color);
    background-color: var(--surface-overlay);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .toolbar-config.toolbar-config-active {
    z-index: 1011;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .toolbar-config .toolbar-config-button {
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    text-align: center;
    top: 345px;
    left: -52px;
    z-index: -1;
    overflow: hidden;
    cursor: pointer;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    transition: background-color 0.2s;
  }

  .toolbar-config .toolbar-config-button .pi {
    font-size: 25px;
  }

  .layout-sidebar {
    background-image: url('./galleria6.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .layout-menu li .layout-menuitem-root-text {
    color: white !important;
  }

  .layout-menu li a {
    color: white;
  }

  .layout-menu li a:hover {
    color: black;
  }

  .productHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toolboxButton {
    color: initial;
    background: transparent;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    width: 105px;
  }

  .toolboxButton {
    .pi {
      font-size: 3rem;
    }
      .p-button-icon-left {
        margin-right: 0;
        margin-bottom: 10px;
      }
  }
  .setting-config {
    position: fixed;
    top: 0;
    padding: 0;
    right: 0;
    width: 27rem;
    z-index: 1001;
    height: 100vh;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%) !important;
    color: var(--text-color);
    background-color: var(--surface-overlay);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .setting-config.setting-config-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 1011;
  }

  .setting-config .setting-config-button {
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
    text-align: center;
    top: 450px;
    left: -52px;
    z-index: -1;
    overflow: hidden;
    cursor: pointer;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    transition: background-color 0.2s;
  }

  .setting-config .setting-config-button .pi {
    font-size: 25px;
  }