*::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #a3a3a3;
    border-radius: 10px;
    padding: 3px;
  }